<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    scrollable
    persistent
  >
    <add-message-contents
      v-if="showMessageForm"
      @message-added="showAppUsers"
      @sending-cancelled="closeModal"
    />
    <select-app-users
      v-if="showAppUsersList"
      :message-contents="messageContents"
      @message-send="messageSend"
      @sending-cancelled="closeModal"
    />
  </v-dialog>
</template>

<script>
import AddMessageContents from './AddMessageContents';
import SelectAppUsers from './SelectAppUsers.vue';

export default {
    name: 'SendEmergencyMsgLanding',
    components: {
        'add-message-contents': AddMessageContents,
        'select-app-users': SelectAppUsers,
    },
    data () {
      return {
        showModal: true,
        showMessageForm: true,
        showAppUsersList: false,
        messageContents: {},
      };
    },
     watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      showAppUsers (val) {
        this.messageContents = val;
        this.showMessageForm = false;
        this.showAppUsersList = true;
      },
      closeModal () {
        this.showMessageForm = false;
        this.showAppUsersList = false;
        this.showModal = false;
      },
      messageSend () {
        this.showMessageForm = false;
        this.showAppUsersList = false;
        this.showModal = false;
        this.$emit('get-alert-histories');
      },
    },
};
</script>
