<template>
  <v-card
    color="#ECEFF1"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-alert
      </v-icon> Select Users
    </h2>
    <br>
    <base-tree-view-without-actions
      :value="treeData"
      class="my-tree-view-style"
    >
      <span slot-scope="{node, path, tree}">
        <input
          id="myCheck"
          type="checkbox"
          :checked="node.$checked"
          @change="onCheckBoxChange(node, tree, path)"
        >
        {{ node.text }}
      </span>
    </base-tree-view-without-actions>
    <br>
    <br>
    <v-card-actions>
      <v-btn
        color="red darken-3"
        @click="cancelSending()"
      >
        Cancel
      </v-btn>
      <v-spacer />
      <v-btn
        class="mt-2"
        color="#FF3700"
        :disabled="!isAppUserSelected"
        @click="sendEmergencyMessage()"
      >
        Send
      </v-btn>
    </v-card-actions>
    <centre-spinner
      :loading="loading"
    />
  </v-card>
</template>
<script>

  import { walkTreeData } from 'he-tree-vue';
  import { getDefaultStructure, addMissingAppUsers } from 'src/helpers/app-user-structure-helper';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';

  export default {
    name: 'SelectAppUsers',
    components: {
      'centre-spinner': spinner,
    },
    props: {
        messageContents: {
          type: Object,
          required: true,
      },
    },
    data () {
      return {
        selectedAppUsers: [],
        treeData: [],
        loading: false,
      };
    },
    computed: {
      savedStructure () {
        return this.$store.getters['appusers/getSavedAppUsersStructure'];
      },
      isAppUserSelected () {
        if (this.selectedAppUsers.length > 0) {
          return true;
        }
        return false;
      },
      paginatedAppUsersStructure () {
        return this.$store.getters['appusers/getAppUsers'];
      },
      appUsers () {
        const records = this.paginatedAppUsersStructure;
        return records.data;
      },
    },
    async mounted () {
      this.loading = true;
      await this.fetchAppusersSavedStructure();
      await this.fetchOrganizationAppUsers();
      this.showTreeList();
      this.loading = false;
    },
    methods: {
      async fetchAppusersSavedStructure () {
        await this.$store.dispatch('appusers/fetchSavedAppUsersStructure').catch(() => {
          this.loading = false;
        });
      },
      async fetchOrganizationAppUsers () {
        this.loading = true;
        await this.$store.dispatch('appusers/fetchAppUsers', {
          params: {
            params: {
              limit: null,
              startPage: null,
            },
          },
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      showTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const appUsers = this.appUsers;
          this.treeData = getDefaultStructure(appUsers);
          return;
        }
        const treeData = this.savedStructure.group;
        const appUsers = this.appUsers;
        this.treeData = addMissingAppUsers(treeData, appUsers);
      },
      onCheckBoxChange (node, tree, path) {
        tree.toggleCheck(node, path);
        const selectedNodes = [];
        tree.walkTreeData((node) => {
          node.$checked && selectedNodes.push(node);
        });
        this.selectedAppUsers = selectedNodes;
      },
      sendEmergencyMessage () {
        this.loading = true;
        const selectedOrgAppUserIds = [];
        walkTreeData(this.treeData, (node) => {
          if (node.$checked && node.id !== undefined) {
            selectedOrgAppUserIds.push(node.id);
          }
        });
        this.$store.dispatch('widgets/sendEmergencyMessage', {
          emergencyMessage: {
            title: this.messageContents.title,
            description: this.messageContents.description,
            app_users: selectedOrgAppUserIds,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Message send successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('message-send');
        }).catch(() => {
          this.loading = false;
        });
      },
      cancelSending () {
        this.$emit('sending-cancelled');
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
input[type="checkbox"] {
  accent-color: #FF3700
}
</style>
