<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-calendar-clock
        </v-icon> Filter Records
      </h2>
      <br>
      <v-form>
        <v-container fluid>
          <v-card-text class="text-body-1 text-center">
            <v-row>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="projectId"
                  :items="projectsList"
                  item-text="name"
                  item-value="id"
                  label="Project"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-datetime-picker
                  v-model="from"
                  label="From"
                  date-format="yyyy-MM-dd"
                  time-format="HH:mm"
                  :text-field-props="{
                    suffix: userTimeZone,
                    class: 'custom-label-color',
                    outlined: true,
                    dense: true,
                  }"
                  :date-picker-props="{
                    headerColor: '#37474F',
                    max: maxStartDate
                  }"
                  :time-picker-props="{
                    headerColor: '#37474F'
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-calendar
                    </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-alarm
                    </v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-datetime-picker
                  v-model="to"
                  label="To"
                  :text-field-props="{
                    persistentHint: true,
                    suffix: userTimeZone,
                    outlined: true,
                    dense: true,
                  }"
                  :date-picker-props="{
                    headerColor: '#37474F',
                    min: minEndDate,
                    max: maxStartDate
                  }"
                  :time-picker-props="{
                    headerColor: '#37474F'
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-calendar
                    </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-alarm
                    </v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              depressed
              default
              @click="cancel()"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              depressed
              default
              @click="submit()"
            >
              Filter
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
  import moment from 'moment-timezone';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';

  export default {
    name: 'FilterSuspiciousCheckIns',
    components: {
      'centre-spinner': spinner,
    },
    data: () => ({
      from: null,
      to: null,
      projectId: null,
      showModal: true,
      maxStartDate: moment().format('YYYY-MM-DD'),
      loading: false,
    }),

    computed: {
      projectsList () {
        return this.$store.getters['projects/getProjectsList'];
      },
      minEndDate () {
        return moment(this.from).format('YYYY-MM-DD');
      },
      userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.getProjectsList();
      this.loading = false;
    },
    methods: {
      cancel () {
        this.showModal = false;
      },
      async getProjectsList () {
        await this.$store.dispatch('projects/fetchProjectsList').catch(() => {
          this.loading = false;
        });
      },
      async submit () {
        this.loading = true;
        await this.$store.dispatch('widgets/fetchFilteredSuspiciousRecords', {
          params: {
            from: this.from,
            to: this.to,
            projectId: this.projectId,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Filtered suspicious record fetched successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
</style>
