<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-arrow-right-outline
      </v-icon> Manual Check-Outs
    </h2>
    <br>
    <div v-if="subscriptionCheck('active')">
      <v-card
        shaped
        color="#CFD8DC"
      >
        <br>
        <v-col
          class="text-right"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                fab
                dark
                outlined
                color="#37474F"
                small
                class="btn-style"
                v-on="on"
                @click="getManualCheckOuts()"
              >
                <v-icon
                  small
                  dark
                >
                  mdi-file-multiple-outline
                </v-icon>
              </v-btn>
            </template>
            <span>ALL Records</span>
          </v-tooltip>
          <v-btn
            color="#FF3700"
            @click="showFilterForm = true"
          >
            Filter
          </v-btn>
        </v-col>
        <hr>
        <br>
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              lg="4"
              md="5"
              sm="12"
            >
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="manualCheckOut.headers"
          :items="manualCheckOuts"
          :search="search"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          disable-sort
          class="elevation-1"
          mobile-breakpoint="100"
        >
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr>
              <td>{{ item.checkin_record.app_user.first_name }} {{ item.checkin_record.app_user.last_name }}</td>
              <td>{{ item.checkin_record.app_user.company.name }}</td>
              <td>
                {{ item.checkin_record.project.name }}
              </td>
              <td>
                {{ item.checkin_record.checkout_time | convertToLocal }}
              </td>
              <td>{{ item.created_at | convertToLocal }}</td>
              <td>
                <v-btn
                  icon
                  small
                  @click.stop
                  @click="expand(!isExpanded)"
                >
                  <v-icon
                    v-if="!isExpanded"
                  >
                    mdi-arrow-down-drop-circle
                  </v-icon>
                  <v-icon v-if="isExpanded">
                    mdi-arrow-up-drop-circle
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card>
                <v-toolbar
                  color="#37474F"
                  dark
                >
                  <v-toolbar-title class="my-tool-bar-title-style">
                    Manual Check-Out Reason
                  </v-toolbar-title>
                </v-toolbar>
                <v-list>
                  <v-list-item-content class="ml-3">
                    <v-list-item-title>
                      <span class="manual-reason">{{ item.reason }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list>
              </v-card>
            </td>
          </template>
        </v-data-table>
        <div class="py-3" />
      </v-card>
      <centre-spinner
        :loading="loading"
      />
      <filter-manual-check-outs
        v-if="showFilterForm"
        @closed="showFilterForm = false"
      />
    </div>
    <div v-else>
      <v-alert
        text
        prominent
        type="warning"
        color="#006883"
        border="left"
        class="alert-style"
      >
        Upgrade to Premium Version to view the CheckIns.
      </v-alert>
    </div>
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import FilterManualCheckOuts from './FilterManualCheckOuts.vue';
  import global from 'src/mixins/global';

  export default {
    name: 'ManualCheckOuts',
    components: {
      'centre-spinner': spinner,
      'filter-manual-check-outs': FilterManualCheckOuts,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        loading: false,
        manualCheckOut: {
          headers: [
            { text: 'Name', align: 'start', value: 'checkin_record.app_user.first_name' },
            { text: 'Company Name', value: 'name' },
            { text: 'Project', value: 'project' },
            { text: 'Recorded Check-Out', value: 'checkout_time' },
            { text: 'Actual Check-Out', value: 'created_at' },
            { text: 'More', value: 'more' },
          ],
        },
        showFilterForm: false,
        expanded: [],
        singleExpand: true,
      };
    },
    computed: {
      manualCheckOuts () {
        const list = this.$store.getters['widgets/getManualCheckOuts'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
      },
    },
    async mounted () {
      await this.subscriptionCheck('active') && await this.getManualCheckOuts();
    },
    methods: {
      async getManualCheckOuts () {
        this.loading = true;
        await this.$store.dispatch('widgets/fetchManualCheckOuts').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
  margin-left: 20px;
}
.type {
  text-transform: capitalize;
}
.btn-style {
  margin-right: 15px;
}
.manual-reason {
  font-weight: bold;
  color: #37474F;
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
