<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-message-alert-outline
      </v-icon> Alerts Histories
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          color="#FF3700"
          @click="sendEmergencyAlert()"
        >
          Send Alert
        </v-btn>
      </v-col>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="alertHistories"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              class="more-link"
              @click="showResponse(item.id)"
            >
              {{ item.title }}
            </td>
            <td>{{ item.description }}</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <font class="success-style">
                Send*
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="showResponse(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click.stop
                @click="deleteAlertMessage()"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <send-emergency-msg-landing
      v-if="showAlertSendForm"
      @get-alert-histories="getLatestAlertHistories"
      @closed="showAlertSendForm = false"
    />
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import SendEmergencyMsgLanding from './SendEmergencyMsgLanding.vue';
  import Constants from 'src/constants';
  import moment from 'moment';
  import global from 'src/mixins/global';

  export default {
    name: 'EmergencyMessages',
    components: {
      'centre-spinner': spinner,
      'send-emergency-msg-landing': SendEmergencyMsgLanding,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        showAlertSendForm: false,
        loading: false,
        headers: [
          { text: 'Alert Title', align: 'start', value: 'title' },
          { text: 'Description', value: 'description' },
          { text: 'Send At', value: 'created_at' },
          { text: 'Status', value: 'status' },
          { text: 'Responses', value: 'more' },
          { text: 'Delete', value: 'delete' },
        ],
      };
    },
    computed: {
      alertHistories () {
        const list = this.$store.getters['widgets/getAlertHistories'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
      },
    },
    async mounted () {
      await this.getAlertHistories();
    },
    methods: {
      async getAlertHistories () {
        this.loading = true;
        await this.$store.dispatch('widgets/fetchAlertHistories').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      sendEmergencyAlert () {
        this.showAlertSendForm = true;
      },
      getLatestAlertHistories () {
        setTimeout(() => {
          this.$store.dispatch('widgets/fetchAlertHistories');
        }, 3000);
      },
      deleteAlertMessage () {
        this.$store.dispatch('alert/onAlert', {
          message: 'You are not authorized to perform this action.',
          type: Constants.ALERT_TYPE_INFO,
        });
      },
      showResponse (alertId) {
        this.$router.push(`widgets/alerts/${alertId}/responses`);
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.success-style {
  color: #2E7D32;
  font-weight: bold;
  font-size: 12px;
}
.more-link{
  cursor: pointer;
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
